import { ThemeProps } from "../../theme/types";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const StyledMain = styled("main")`
  ${({ theme }: ThemeProps) => css`
    display: 'flex'  
    flex-grow: 1;
    bakcground-color:  ${theme.appColors.white};
    color: ${theme.palette.text.primary};
    display: flex;
    /* width: 98%;
    height: 98%; */
    flex-direction: column;
    width: 100%;
  `}
`;
