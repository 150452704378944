import * as React from "react";
import Box from "@mui/material/Box";
import Email from "@mui/icons-material/Email";
import Phone from "@mui/icons-material/Phone";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useTheme } from "../../theme/use-theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useRouter } from "next/router";
import FilterList from "@mui/icons-material/FilterList";
import { useStorage } from "../../hooks";
import { Button, ButtonGroup, lighten } from "@mui/material";
import AppContext from "../../../utils/AppContext";

type CategoryButtons = {
  id: number;
  text: string;
  filterValue?: string;
};

const categoryButtons: CategoryButtons[] = [
  { id: 1, text: "Alles" },
  { id: 2, text: "VCA-Examen", filterValue: "vca" },
  // { id: 3, text: "VPS", filterValue: "vps" },
  { id: 6, text: "E-Learning", filterValue: "elearning" },
  { id: 5, text: "Lesboeken", filterValue: "lesboeken" },
  { id: 4, text: "Pas/Diploma", filterValue: "pasdiploma" },
];

export const Header: React.VFC<{
  title?: string;
  open?: boolean;
  isHomePage?: boolean;
  // eslint-disable-next-line react/prop-types
}> = ({ isHomePage }) => {
  const theme = useTheme();

  const { localAppState, setLocalAppState } = React.useContext(AppContext);
  const smallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const mediumDevice = useMediaQuery(theme.breakpoints.down("md"));
  const router = useRouter();
  const { filter } = router.query;
  const useIsomorphicLayoutEffect =
    typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;
  const { getItem, setItem } = useStorage();
  const [activeButtonID, setActiveButtonID] = React.useState(
    localAppState.activeButtonID
  );
  const [filterOpen, setFilterOpen] = React.useState(false);
  const orderDetailsList = getItem("ShoppingBasketList");
  const orderList = orderDetailsList ? JSON.parse(orderDetailsList) : [];
  const shoppingCartWidth = 65;
  const shoppingCartHeight = 35;
  const appBarHeight = smallDevice ? 40 : theme.dimensions.heightAppBar;

  const handleBasketClick = () => {
    router.push({
      pathname: "/ShoppingCartPage",
    });
  };

  useIsomorphicLayoutEffect(() => {
    if (filter) {
      const foundFilterItem = categoryButtons.find(
        (item) => item.filterValue === filter
      );
      if (foundFilterItem) {
        setActiveButtonID(foundFilterItem.id);
        setLocalAppState({
          activeButtonID: foundFilterItem.id,
        });
        setFilterOpen(false);
      }
    }
  }, [filter]);

  const handleOnClick = (item: CategoryButtons) => {
    setActiveButtonID(item.id);
    setLocalAppState({
      activeButtonID: item.id,
    });
    setFilterOpen(false);
  };

  const renderButton = (item: CategoryButtons) => {
    return (
      <Button
        key={item.id}
        onClick={() => handleOnClick(item)}
        style={{ minWidth: 120 }}
        sx={{
          borderRadius: 0,
          // borderColor: theme.appColors.bodyColorGrey,
          backgroundColor:
            !smallDevice && activeButtonID === item.id
              ? "transparent"
              : "white",
          background:
            !smallDevice && activeButtonID === item.id
              ? `linear-gradient(${theme.appColors.primary} 0 0) center bottom / 100% 2px no-repeat`
              : "white",
          backgroundSize: !smallDevice ? "60% 3px" : undefined,
          height: 30,
          ":hover": {
            color: theme.appColors.tertiaryLight,
          },

          color:
            activeButtonID === item.id
              ? theme.appColors.primary
              : theme.appColors.bodyColorGrey,
        }}
      >
        {item.text}
      </Button>
    );
  };

  const handleLogoClicked = () => {
    router.push({
      pathname: "https://bestel.vcaexamen.nl",
    });
  };

  return (
    <Box
      width={"100%"}
      sx={{ backgroundColor: theme.appColors.primaryLight, overflow: "hidden" }}
    >
      <AppBar
        position="fixed"
        sx={{
          height: appBarHeight,
          backgroundColor: theme.appColors.white,
          zIndex: theme.zIndex.drawer + 1,
          width: "100%",
          overflow: "hidden",
          top: 0,
          alignItems: "center",
        }}
      >
        <Stack
          height={appBarHeight}
          width="100%"
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            id="TopBar"
            width={"100%"}
            height={"60"}
            alignItems="center"
            sx={{
              backgroundColor: !smallDevice
                ? lighten(theme.appColors.primary, 0.2)
                : "white",
            }}
          >
            <Stack
              id="topBar"
              width="100%"
              maxWidth={1116}
              paddingX={1}
              direction={"row"}
            >
              {/* {mediumDevice && (
                <Stack alignItems="center" direction="row" paddingLeft={1}>
                  <Stack
                    style={{ cursor: "pointer" }}
                    onClick={handleLogoClicked}
                    alignItems="center"
                  >
                    <img
                      src="https://storage.googleapis.com/cbex/logo-vcaexamen.png"
                      alt="vcaexamen-logo"
                      height={"60px"}
                    />
                  </Stack>
                </Stack>
              )} */}
              {!mediumDevice && (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  direction="row"
                  spacing={1}
                  width={"100%"}
                >
                  <IconButton
                    sx={{ padding: { xs: 0 } }}
                    size="small"
                    onClick={(e) => {
                      //@ts-ignore
                      window.location = `mailto:  info@vcaexamen.nl?subject=Bericht vanaf de website VCAexamen.nl`;
                      e.preventDefault();
                    }}
                    style={{ backgroundColor: "transparent" }}
                    color="inherit"
                  >
                    <Email
                      sx={{
                        color: theme.appColors.white,
                        height: 20,
                      }}
                    />
                    <Typography
                      color={theme.appColors.white}
                      fontSize={theme.dimensions.headerFontSize}
                    >
                      info@vcaexamen.nl
                    </Typography>
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      //@ts-ignore
                      window.location = `tel:31850705318`;
                      e.preventDefault();
                    }}
                    size="small"
                    style={{ backgroundColor: "transparent" }}
                    color="inherit"
                  >
                    <Phone
                      sx={{
                        color: theme.appColors.white,
                        height: 20,
                      }}
                    />
                    {!smallDevice && (
                      <Typography
                        color={theme.appColors.white}
                        fontSize={theme.dimensions.headerFontSize}
                      >
                        085 - 0705318
                      </Typography>
                    )}
                  </IconButton>
                </Stack>
              )}
            </Stack>
          </Stack>
          <Stack
            id="MenuBar"
            justifyContent={"center"}
            alignItems="center"
            width="100%"
            // minWidth={1200}
            height={"100%"}
            direction={"row"}
            paddingX={2}
          >
            {!mediumDevice && (
              <Stack
                id="logoStack"
                style={{ cursor: "pointer", marginTop: 2 }}
                onClick={handleLogoClicked}
              >
                <img
                  src="https://storage.googleapis.com/cbex/logo-vcaexamen.png"
                  alt="vcaexamen-logo"
                  height={"50px"}
                />
              </Stack>
            )}
            {isHomePage && (
              <Stack
                id="filterStack"
                justifySelf={"center"}
                justifyContent={"center"}
                alignItems="center"
                // bgcolor={"red"}
                width={!smallDevice ? 980 : 300}
                // maxWidth={!smallDevice ? 980 : undefined}
                direction={{ xs: "column", sm: "row" }}
                sx={
                  smallDevice
                    ? {
                        position: "fixed",
                        left: "50%",
                        transform: "translate(-50%, 0)",
                        top: smallDevice ? "2px" : undefined,
                      }
                    : {
                        // justifySelf: "center",
                        // flexGrow: 2,
                      }
                }
              >
                {smallDevice && (
                  <Stack justifyContent={"space-between"} direction={"row"}>
                    <Button
                      style={{ minWidth: 120, background: "white" }}
                      variant="text"
                      onClick={(e: any) => {
                        e.preventDefault();
                        setFilterOpen(!filterOpen);
                      }}
                    >
                      {
                        categoryButtons.find(
                          (activeButton) => activeButton.id === activeButtonID
                        )?.text
                      }
                      <FilterList />
                    </Button>
                    <Stack
                      mt={0.2}
                      sx={{
                        backgroundRepeat: "no-repeat",
                        backgroundImage: `url("data:image/svg+xml,%3Csvg width='${shoppingCartWidth}' height='${shoppingCartHeight}' viewBox='0 0 97 68' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='48.5' cy='19.5' r='48.5' fill='%23FF9D00'/%3E%3C/svg%3E%0A")`,
                        width: shoppingCartWidth,
                        height: shoppingCartHeight,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Stack>
                        <div
                          style={{
                            position: "relative",
                            top: 25,
                            right: -15,
                            textAlign: "center",
                            backgroundColor: theme.palette.success.light,
                            height: 15,
                            width: 15,
                            borderRadius: "50%",
                            fontSize: theme.fontSizes[2],
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {orderList.length}
                        </div>
                      </Stack>

                      <IconButton
                        size="large"
                        style={{ backgroundColor: "transparent" }}
                        color="inherit"
                        onClick={handleBasketClick}
                      >
                        <ShoppingCartOutlinedIcon
                          sx={{
                            color: theme.appColors.bodyColorGrey,
                            width: shoppingCartWidth * 0.8,
                            height: shoppingCartHeight * 0.8,
                            marginBottom: "15px",
                          }}
                        />
                      </IconButton>
                    </Stack>
                  </Stack>
                )}
                {(!smallDevice || filterOpen) && (
                  <ButtonGroup
                    variant={smallDevice ? "outlined" : "text"}
                    fullWidth={smallDevice}
                    size="small"
                    aria-label="outlined primary button group"
                    orientation={smallDevice ? "vertical" : "horizontal"}
                  >
                    {categoryButtons.map((item: CategoryButtons) =>
                      renderButton(item)
                    )}
                  </ButtonGroup>
                )}
              </Stack>
            )}
            {!isHomePage && (
              <Stack
                id="filterStack"
                justifySelf={"center"}
                justifyContent={"center"}
                alignItems="center"
                // bgcolor={"red"}
                width={!smallDevice ? 980 : 300}
                // maxWidth={!smallDevice ? 980 : undefined}
                direction={{ xs: "column", sm: "row" }}
                sx={
                  smallDevice
                    ? {
                        position: "fixed",
                        left: "50%",
                        transform: "translate(-50%, 0)",
                        top: smallDevice ? "2px" : undefined,
                      }
                    : {
                        // justifySelf: "center",
                        // flexGrow: 2,
                      }
                }
              ></Stack>
            )}
            {!smallDevice && (
              <Stack width={187}>
                <Stack
                  id="ShoppingCartStack"
                  mt={0.2}
                  sx={{
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url("data:image/svg+xml,%3Csvg width='${shoppingCartWidth}' height='${shoppingCartHeight}' viewBox='0 0 97 68' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='48.5' cy='19.5' r='48.5' fill='%23FF9D00'/%3E%3C/svg%3E%0A")`,
                    width: shoppingCartWidth,
                    height: shoppingCartHeight,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Stack>
                    <div
                      style={{
                        position: "relative",
                        top: 25,
                        right: -15,
                        textAlign: "center",
                        backgroundColor: theme.palette.success.light,
                        height: 15,
                        width: 15,
                        borderRadius: "50%",
                        fontSize: theme.fontSizes[2],
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      {orderList.length}
                    </div>
                  </Stack>

                  <IconButton
                    size="large"
                    style={{ backgroundColor: "transparent" }}
                    color="inherit"
                    onClick={handleBasketClick}
                  >
                    <ShoppingCartOutlinedIcon
                      sx={{
                        color: theme.appColors.bodyColorGrey,
                        width: shoppingCartWidth * 0.8,
                        height: shoppingCartHeight * 0.8,
                        marginBottom: "15px",
                      }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </AppBar>
    </Box>
  );
};
